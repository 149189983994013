/*alert('requrire page index 1');


class Foo {
    constructor(){
        this.value = "foobar"
    }
    static instance() {
        return new Foo()
    }
    getValue() {
        return this.value
    }
}

console.log(Foo.instance().getValue())
*/
require('./pages/index/index.css');
import { CountUp } from './vendors/countUp/dist/countUp.min.js';




jQuery(function($) {



    app.initIndex();
    $(document).on('turbolinks:load', app.initIndex);

    $(document).on('turbolinks:before-visit', function() {
        //Turbolinks.clearCache();
        //alert('clear index');
    });

    // Code using $ as usual goes here; the actual jQuery object is jq2
});


app.circleProgress = null;
app.getInfoXhr = null;

app.initIndex = function() {

    if (jQuery('.page-home').length > 0) {


        /*
        if(jQuery(window).width() < 1000){
             let st = $(window).scrollTop();
             if(!jQuery('#navbarMain').hasClass('navbar-fixed-top')){

                    if(st > 700){
                      jQuery('#navbarMain').addClass("navbar-fixed-top navbar-shadow")
                    }else{
                      jQuery('#navbarMain').removeClass("navbar-fixed-top navbar-shadow")
                    } 

                 $(document).scroll(function() {
                    let st = $(window).scrollTop();
                    if(st > 700){
                      jQuery('#navbarMain').addClass("navbar-fixed-top navbar-shadow")
                    }else{
                      jQuery('#navbarMain').removeClass("navbar-fixed-top navbar-shadow")
                    }
                });

             }  
        }*/

        //LIMITO ERRORI NELL'INPUT, per ora lo rimuovo
        //$("input[name=targa]").inputmask({ regex: "[a-zA-Z0-9]{5,}", "placeholder": "" });

        var timeoutSubmit = null;
        //Bind submit all'invio dell'enter
        jQuery('input[name=targa]').bind('keydown keypress keyup', function(e) {

            let evt = (e) ? e : event;
            var target = (evt.target) ? evt.target : evt.srcElement;
            var _form = jQuery(this).parents('form').get(0); //target.form;
            var charCode = (evt.charCode) ? evt.charCode :
                ((evt.which) ? evt.which : evt.keyCode);
            if (charCode == 13) {

                //evt.preventDefault();
                clearTimeout(timeoutSubmit);
                timeoutSubmit = setTimeout(function() {

                    jQuery('.btn-check').trigger('click');
                }, 200);

            }
            return true;

        });

        //Nascondo il transfer preloader
        jQuery(".transfer-wrapper").addClass('d-none');
        jQuery("body").removeClass('no-scrollbars');
        //Cookies.set('ABORTCURL', '1', { expires: 1 });
        initializeTransfer();


        jQuery('.btn-check').removeClass('disabled');

        jQuery('.btn-check').on('click', function(e) {

            //
            let evt = (e) ? e : event;
            evt.preventDefault();

            let form = jQuery(this).parents('form').get(0);
            let targa = jQuery("input[name=targa]").val();

            //Rimuovo gli spazi bianchi
            targa = targa.replace(/\s/g, '');
            targa = targa.replace(/[^0-9A-Z]/gi, '');
            jQuery("input[name=targa]").val(targa);

            jQuery('.__btn-check').addClass('disabled');
            setTimeout(() => {
                jQuery('.btn-check').removeClass('disabled');
            }, 100);


            clearTimeout(timeoutSubmit);
            timeoutSubmit = setTimeout(() => {






                let options = {
                    url: "bl.php?action=checkTarga",
                    beforeSubmit: () => {
                        //Cookies.set('ABORTCURL', '0', { expires: 1 });
                        showTransfer();
                    },
                    callbackOK: (data) => {
                        getInfo(data);
                        //Turbolinks.visit('infomezzo');
                    },
                    callbackKO: (response) => {

                        setTimeout(() => {
                            jQuery(".transfer-wrapper").addClass('d-none');
                            jQuery("body").removeClass('no-scrollbars');

                            let error = response.error;
                            let message = error.message || null;

                            if (message.length > 0) {
                                core.message.error(message);
                            }
                        }, 200);



                        //Turbolinks.visit('infomezzo');
                    }
                }

                //Cancello la richiesta precedente
                if (app.sourceXhrInfo) {
                    app.sourceXhrInfo.cancel();
                    app.sourceXhrInfo = null;
                }


                core.form.ajaxSubmit(form, options);

            }, 100);

        });


        //Contattaci
        jQuery('.btn-contactus').removeClass('disabled');
        jQuery('.btn-contactus').on('click', function() {

            let form = jQuery(this).parents('form').get(0);

            jQuery('.btn-contactus').addClass('disabled');

            setTimeout(() => {
                jQuery('.btn-contactus').removeClass('disabled');
            }, 100);

            let options = {
                url: "bl.php?action=contactus",
                beforeSubmit: () => {
                    jQuery('.preloader-form').show();
                },
                callbackOK: (data) => {

                    //Riapro la modale thanks
                    setTimeout(function() {

                        jQuery('.preloader-form').hide();
                        $('#modalContact').modal('hide');
                        $('#modalContactSuccess').modal('show');

                    }, 1000);


                }
            };


            core.form.ajaxSubmit(form, options);

        });


        //Download app
        jQuery('.btn-download-app').removeClass('disabled');
        jQuery('.btn-download-app').on('click', function() {

            let form = jQuery(this).parents('form').get(0);

            jQuery('.btn-download-app').addClass('disabled');

            setTimeout(() => {
                jQuery('.btn-download-app').removeClass('disabled');
            }, 100);

            let options = {
                url: "bl.php?action=downloadapp",
                beforeSubmit: () => {
                    jQuery('.preloader-form', form).show();
                },
                callbackOK: (data) => {

                    //Riapro la modale thanks
                    setTimeout(function() {

                        jQuery('.preloader-form').hide();
                        $('#modalDownloadSuccess').modal('show');
                        form.reset();
                        $('html,body').animate({
                            scrollTop: 0
                        }, 0);


                    }, 500);


                }
            };


            core.form.ajaxSubmit(form, options);

        });



        //Button coupon code
        jQuery('.btn-coupon-code').removeClass('disabled');
        jQuery('.btn-coupon-code').on('click', function() {

            let form = jQuery(this).parents('form').get(0);

            jQuery('.btn-coupon-code').addClass('disabled');

            setTimeout(() => {
                jQuery('.btn-coupon-code').removeClass('disabled');
            }, 100);

            let options = {
                url: "bl.php?action=convertcoupon",
                beforeSubmit: () => {
                    jQuery('.preloader-form', form).show();
                },
                callbackOK: (data) => {

                    //Riapro la modale thanks
                    setTimeout(function() {

                        jQuery('.preloader-form').hide();
                        $('#modalCouponSuccess').modal('show');
                        form.reset();


                    }, 500);


                }
            };


            core.form.ajaxSubmit(form, options);

        });



        jQuery(".btn-share-fb").on('click', function(event) {
            event.preventDefault();
            shareFacebook();
        });


        //COUNTUP
        if (!Cookies.get('countup')) {

            jQuery('.metric-counter-plus').addClass('invisible');

            var options = {  
                useEasing: true,
                  useGrouping: true,
                  separator: ',',
                  decimal: '.',
                  prefix: '',
                  suffix: ''
            };
            $('.metric-counter').each(function() {

                var endNum = $(this).data('count');
                var countUp = new CountUp(this, endNum);
                countUp.start(function() {
                    Cookies.set('countup', '1', { expires: 1 })
                    jQuery('.metric-counter-plus').removeClass('invisible');
                });
            });
        } else {
            //non faccio nulla
        }




    }






};


function initializeTransfer() {

    let strokeWidth = 7;
    let trailWidth = 8;

    /*if(core.utils.getClientWidth() < 992){
        strokeWidth = 7;
        trailWidth = 8;
    }*/
    //Preloader
    jQuery("#transferProgress").empty();
    let circle = new ProgressBarJs.Circle('#transferProgress', {
        color: '#000',
        trailColor: '#DDDDDD',
        strokeWidth: strokeWidth,
        trailWidth: trailWidth,
        duration: 3000,
        easing: 'easeInOut',
        step: function(state, circle) {

            //circle.path.setAttribute('stroke', state.color);
            //circle.path.setAttribute('stroke-width', state.width);

            var value = Math.round(circle.value() * 100);
            if (value === 0) {
                circle.setText('0%');
            } else {
                circle.setText(value + '%');
            }

        }
    });
    //circle.text.style.fontSize = '2rem';
    //circle.text.style.fontWeight = 'bold';
    //circle.text.style.paddingLeft = '15px';

    app.circleProgress = circle;

}

function appendAd(adslot) {

    // based on code found at:
    // https://stackoverflow.com/questions/55695667/adsense-injecting-style-tag-into-my-page-in-chrome
    var flex = document.getElementsByClassName('adsense-height-fix')[0];
    const observer = new MutationObserver(function(mutations, observer) {
        flex.style.height = "";
    });

    observer.observe(flex, {
        attributes: true,
        attributeFilter: ['style']
    });
    setTimeout(function() {

        //1462242050

        let adFormat = 'auto'; // (core.utils.getClientWidth() < 900) ? 'rectangle' : 'auto';
        let ad = "<ins class='adsbygoogle ad_responsive_1'\
                style='display:block;margin:7px;'\
                data-ad-client='ca-pub-9115578169053603'\
                data-ad-slot='" + adslot + "'></ins>";






        /*data-full-width-responsive=\"true\"\
        data-ad-format=\"auto\"\
        */
        //             data-ad-format=\"auto\"\ // data-ad-format=\"" + adFormat + "\"\

        jQuery(ad).appendTo(".transfer-ads");


        (adsbygoogle = window.adsbygoogle || []).push({});
    }, 1500);
}

function showTransfer() {

    jQuery('body').addClass('no-scrollbars');

    let circle = app.circleProgress;
    jQuery('#transferLabel').text("Recupero informazioni");
    circle.animate(0);

    jQuery(".transfer-wrapper").removeClass('d-none');



    //++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //GOOGLE ADSENSE IN RICERCA
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //Faccio l'inject di adsense
    appendAd(1462242050);

    appendAd(4350500247);

    appendAd(1890205476);

    //------------------------------------------------

    //Animazione
    circle.animate(0.1, {
        duration: 2000
    }, function() {

        jQuery('#transferLabel').text("Recupero dati mezzo");
        circle.animate(0.3, {
            duration: 3000
        }, function() {

            jQuery('#transferLabel').text("Recupero dati assicurazione");
            circle.animate(0.5, {
                duration: 6000
            }, function() {

                jQuery('#transferLabel').text("Recupero revisioni");
                circle.animate(0.8, {
                    duration: 14000
                }, function() {

                    jQuery('#transferLabel').text("Recupero revisioni");
                    circle.animate(0.99, {
                        duration: 23000
                    });

                });

            });

        });

    });

}



function getInfo(params) {


    axios.defaults.headers.common['X-Requested-With'] = 'xmlhttprequest';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[property="_xtk"]').attr("content");

    let circle = app.circleProgress;


    //let params = getUrlParams();

    if (params.targa && params.targa.length > 0 && params.h && params.h.length > 0) {


        let source = axios.CancelToken.source();
        let token = source.token;


        let url0 = "bl.php?action=fetchrevisioni";

        let data0 = {
            targa: params.targa,
            h: params.h
        }

        let options0 = {};

        let url01 = "bl.php?action=fetchultimerevisioni";

        let data01 = {
            targa: params.targa,
            h: params.h
        }

        let options01 = {};


        let url1 = "bl.php?action=fetchinfo";

        let data1 = {
            targa: params.targa,
            h: params.h
        }

        let options1 = {};

        let url2 = "bl.php?action=fetchinfo2";

        let data2 = {
            targa: params.targa,
            h: params.h
        }

        let options2 = {};


        let url3 = "bl.php?action=fetchinfo3";

        let data3 = {
            targa: params.targa,
            h: params.h
        }

        let options3 = {};





        data0 = $.param(data0);
        data01 = $.param(data01);
        data1 = $.param(data1);
        data2 = $.param(data2);
        data3 = $.param(data3);



        //PER TEST MULTI
        let urlInfoMulti = "bl.php?action=infomezzo";

        let dataInfoMulti = {
            targa: params.targa,
            h: params.h
        }
        let optionsInfoMulti = {};
        dataInfoMulti = $.param(dataInfoMulti);



        //Cancello eventualmente e salvo per cancellazione chiamata
        if (app.sourceXhrInfo) {
            app.sourceXhrInfo.cancel();
            app.sourceXhrInfo = null;
        }

        app.sourceXhrInfo = source;

        axios.all([
                //axios.post(url0, data0),
                //axios.post(url01, data01),
                //axios.post(url1, data1),
                //axios.post(url2, data2),
                //axios.post(url3, data3)
                axios.post(urlInfoMulti, dataInfoMulti, { timeout: 0 })
            ])
            .then((res) => {

                jQuery('body').removeClass('no-scrollbars');

                //console.log('AXTH->>>');
                //console.log(res);
                console.log('Then');
                console.log(res);
                //alert(res.isJson());

                let data = res[0].data;
                console.log(data);
                if (typeof data === 'object') {
                    if (data.redirect && data.redirect.length > 0) {
                        let url = data.redirect;
                        redirect(url);

                    }
                } else {
                    console.log("data not object");
                    redirect('index');
                }








                //jQuery(".transfer-wrapper").hide();
                //Faccio il redirect ad infomezzo passando l'hash


            }).catch(error => {
                console.log('Catch error, check timeout');
                console.log(error);
                jQuery('body').removeClass('no-scrollbars');

                //Forse redirect all'index?

                if (error.response) {
                    // client received an error response (5xx, 4xx)
                    //alert('xxxxxxxx');
                } else if (error.request) {
                    // client never received a response, or request never left
                    //alert('2');
                } else {
                    // anything else
                    //alert('3');
                }




                redirect('index');


            });

    } else {
        console.log('getinfo invalid params');
        redirect('index');

    }

}